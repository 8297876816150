import Accordion from 'accordion-js';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const currentAccordionID = window.location.hash;
  const containers = document.querySelectorAll('.accordion-container');

  if (0 < containers.length) {
    containers.forEach((container) => {
      const block = container.parentElement;
      let currentAccordion = null;
      const openedAccordions = [];

      if (currentAccordionID) {
        currentAccordion = container.querySelector('.ac' + currentAccordionID);
      }

      if (currentAccordion) {
        const currentGroup = currentAccordion.parentNode;
        const currentIndex = Array.prototype.indexOf.call(
          currentGroup.children,
          currentAccordion
        );
        openedAccordions.push(currentIndex);
      }

      new Accordion(container, {
        duration: 400,
        showMultiple: true,
        openOnInit: openedAccordions,
      });

      if (block.classList.contains('has-numeroted-accordions')) {
        const accordionTitles = block.querySelectorAll(
          '.accordion__header__title'
        );

        accordionTitles.forEach((accordionTitle, index) => {
          const number = document.createElement('span');
          let count = index + 1;

          if (10 > count) {
            count = '0' + count;
          }
          number.textContent = count + ' ';
          number.classList.add('accordion__header__count');
          accordionTitle.parentElement.insertBefore(number, accordionTitle);
        });
      }
    });
  }
});
